window.trans = {};

window.trans.ro = {
    "OnlineANPC":"https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO",
    "OnlineANPCAlt":"Soluționarea online a litigiilor",
    "adrese-inregistrate-address": "Adresa - Strada, Număr, Bloc, Scara, Apartament, etc.",
    "Simplu rapid și exclusiv online": "Simplu, rapid și exclusiv online",
    "Sepsiszentgyörgy": "Sfântu Gheorghe",
    "Csiba": "Ciba",
    "Csíkcsicsó": "Ciceu",
    "Csíkcsomortán": "Șoimeni",
    "Csíkdelne": "Delnița",
    "Csíkmindszent": "Misentea",
    "Csíkpálfalva": "Păuleni-Ciuc",
    "Csíkszentimre": "Sântimbru",
    "Csíkszentkirály": "Sâncrăieni",
    "Csíkszentlélek": "Leliceni",
    "Csíkszentsimon": "Sânsimion",
    "Csíkszereda": "Miercurea-Ciuc",
    "Csíkszereda (Csíksomlyó)": "Miercurea-Ciuc (Șumuleu Ciuc)",
    "Csíkszereda (Csobotfalva)": "Miercurea-Ciuc (Cioboteni)",
    "Csíkszereda (Fitód)": "Miercurea-Ciuc (Fitod)",
    "Csíkszereda (Taploca)": "Miercurea-Ciuc (Cartier Toplița)",
    "Csíkszereda (Zsögöd)": "Miercurea-Ciuc (Jigodin-Băi)",
    "Angyalos": "Angheluș",
    "Árkos": "Arcuș",
    "Bikfalva": "Bicfalău",
    "Bodok": "Bodoc",
    "Előpatak": "Vâlcele",
    "Étfalva": "Etfalău",
    "Farkasvágó": "Lunca Calnicului",
    "Gidófalva": "Ghidfalău",
    "Holzindustrie Schweighofer (Réty)": "Holzindustrie Schweighofer (Reci)",
    "Illyefalva": "Ilieni",
    "Kilyén": "Chilieni",
    "Kökös": "Chichiș",
    "Kőröspatak": "Valea Crișului",
    "Lisznyó": "Lisnău",
    "Réty": "Reci",
    "Sepsiszentgyörgy (Benedek Mező)": "Sfântu Gheorghe (Câmpul Benedek)",
    "Sepsiszentgyörgy (Szalomér)": "Sfântu Gheorghe (Salomer)",
    "Sepsiszentgyörgy (Szépmező)": "Sfântu Gheorghe (Câmpul Frumos)",
    "Sepsiszentkirály": "Sâncraiu",
    "Sugásfürdő": "Șugaș Băi",
    "Szentivánlaborfalva": "Sântionlunca",
    "Szotyor": "Coșeni",
    "Uzon": "Ozun",
    "Zalán": "Zălan",
    "Zoltán": "Zoltan",
    "Kovászna": "Covasna",
    "Brassó": "Brașov",
    "Hargita": "Harghita",
    "Kálnok": "Calnic",
    "Bélafalva": "Belani",
    "Bereck": "Brețcu",
    "Csernáton": "Cernat",
    "Csomortán": "Lutoasa",
    "Esztelnek": "Estelnic",
    "Futásfalva": "Alungeni",
    "Gelence": "Ghelința",
    "Haraly": "Haraly",
    "Hatolyka": "Hătuica",
    "Hilib": "Hilib",
    "Ikafalva": "Icafalău",
    "Imecsfalva": "Imeni",
    "Kézdialmás": "Mereni",
    "Kézdikővár": "Petriceni",
    "Kézdimárkosfalva": "Mărcușa",
    "Kézdimartonfalva": "Mărtineni",
    "Kézdimartonos": "Mărtănuș",
    "Kézdisárfalva": "Tinoasa",
    "Kézdiszárazpatak": "Valea Seacă",
    "Kézdiszentkereszt": "Poian",
    "Kézdiszentlélek": "Sânzieni",
    "Kiskászon": "Cașinu Mic",
    "Kurtapatak": "Valea Scurtă",
    "Lemhény": "Lemnia",
    "Nyújtód": "Lunga",
    "Ozsdola": "Ojdula",
    "Szászfalu": "Săsăuși",
    "Székelypetőfalva": "Peteni",
    "Szentkatolna": "Cătălina",
    "Torja": "Turia",
    "Zabola": "Zăbala",
    "Kézdivásárhely": "Târgu Secuiesc",
    "Barátos": "Brateș",
    "Cófalva": "Țufalău",
    "Csomakőrös": "Chiuruș",
    "Lécfalva": "Leț",
    "Nagyborosnyó": "Boroșneu Mare",
    "Orbaitelek": "Telechia",
    "Páké": "Pachia",
    "Papolc": "Păpăuți",
    "Székelytamásfalva": "Tamașfalău",
    "Szörcse": "Surcea",
    "Zabola": "Zăbala",
    "Zágon": "Zagon",
    "Kovászna": "Covasna",
    "order_send_in_progress": "Comanda este în curs de trimitere vă rugăm așteptați. Dacă aveți conexiune la internet slabă și după un minut tot nu puteți trimite comanda vă rugăm să dați refresh paginii și să încercați din nou.",
    "strada-placeholder": "Te rog să introduci strada",
    "google-address-label": "Adresa: strada,localitatea",
    "google-address-placeholder": "Te rog să introduci adresa",
    "google-address-number": "Număr",
    "google-address-block": "Bloc",
    "google-address-staircase": "Scara",
    "google-address-floor": "Etaj",
    "google-address-apartment": "Apartament",
    "address-name": "Nume adresă (acasă, loc de muncă) *",
    "address-deleted": "Adresa a fost șters cu succes",
    "restaurant-doesnt-deliver-to-this-address": "{0} nu livrează în {1}",
    "select-nr-or-items": "alege {0} bucăți?",
    "select-nr-or-items-alert": "Alege {0} bucăți din categoria {1}",
    "select-min-nr-or-items-alert": "Alege minimum {0} bucăți din categoria {1}",
    "select-max-nr-or-items-alert": "Alege maximum {0} bucăți din categoria {1}",
    "momentan-restaurant-inchis": "Momentan restaurantul este închis",
    "adrese-inregistrate": "Adrese înregistrate",
    "payment-failed": "Plată eșuată",
    "payment-failed-description": "Plata prin card nu s-a realizat.",
    "payment-restart": "Încerc din nou",
    "addressNote": "Comentariu pentru adresă",
    "withoutCutlery": "Fără Tacâmuri",
    "request-account-removal-message-1": "Această pagină, facilitează ștergerea definitivă a contului dvs. de utilizator creat în aplicația mobilă Ospătarul din sistemul nostru. Vă rugăm să rețineți că ștergerea contului este permanentă și toate datele stocate în aplicația noastră vor fi, de asemenea, șterse.",
    "request-account-removal-message-2": "Vă rugăm să completați formularul de mai jos pentru a trimite o cerere de ștergere. De îndată ce primim solicitarea dumneavoastră, o vom procesa și vom trimite o notificare la adresa de e-mail specificată despre succesul ștergerii."
};

window.trans.en = {
    "OnlineANPC":"https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=EN",
    "OnlineANPCAlt":"Online Dispute Resolution",
    "Program livrare mâncare": "Shipment program",
    "Zilnic": "Daily",
    "închis": "close",
    "Detalii livrare pe localități": "Shipment for localities",
    "Comandă minimă": "Minimal order",
    "Taxă livrare": "Delivery cost",
    "Livrare în": "Delivery in",
    "minute": "minutes",
    "Ridicare personală": "Personal pickup",
    "Datele au fost salvate": "Data successfully saved",
    "payment-failed": "Payment failed",
    "payment-failed-description": "Unfortunately your card payment was unsuccessful.",
    "withoutCutlery": "I don't ask for cutlery",
    "addressNote": "Note for the address",
};

window.trans.hu = {
    "OnlineANPC":"https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=HU",
    "OnlineANPCAlt":"Online vitarendezés",
    "Simplu rapid și exclusiv online": "Egyszerű, gyors, kizárólag online",
    "Acum ai acces la cele mai bune restaurante din zona ta": "Egy kattintásra vagy a környék legmenőbb éttermeitől",
    "Program livrare mâncare": "Rendelésfelvétel",
    "Zilnic": "Naponta",
    "Luni": "Hétfő",
    "Marți": "Kedd",
    "Miercuri": "Szerda",
    "Joi": "Csütörtök",
    "Vineri": "Péntek",
    "Sâmbătă": "Szombat",
    "Duminică": "Vasárnap",
    "închis": "zárva",
    "Închis": "Zárva",
    "Detalii livrare pe localități": "Szállítás információk településenként",
    "Comandă minimă": "Minimális rendelési érték",
    "Taxă livrare": "Szállítási díj",
    "Livrare în": "Szállítás",
    "minute": "perc",
    "Ridicare personală": "Személyes felvétel",
    "Informații nutriționale": "Termékösszetétel",
    "gratis": "ingyenes",
    "Selectează": "Kiválaszt",
    "gratuit": "ingyenes",
    "max. selectabil": "max. választható",
    "min. selectabil": "min. választható",
    "max. gratuit": "max. ingyenes",
    "Produs adăugat în coș": "A termék a kosárba került",
    "Total comandă": "Rendelés összértéke",
    "Adaugă în coș": "Kosárba teszem",
    "Cost ambalaj": "Csomagolási díj",
    "de la": "Alapár:",
    "Toate": "Összes",
    "Acum": "Most",
    "Acasă": "Kezdőlap",
    "Restaurante": "Vendéglők",
    "Sfantu Gheorghe": "Sepsiszentgyörgy",
    "Sfantu  Gheorghe": "Sepsiszentgyörgy",
    "Sfântu Gheorghe": "Sepsiszentgyörgy",
    "în Sfântu Gheorghe": "Sepsiszentgyörgyön",
    "Ora și data livrării": "Szállítási dátum és idő",
    "Dată de livrare invalidă": "Hibás szállítási időpont",
    "Comanda ta": "Rendelésed",
    "Vezi coș": "Kosár",
    "Taxa livrare": "Szállítási díj",
    "Italian American": "Olasz Amerikai",
    "Romanian": "Román",
    "Turkish": "Török",
    "Vegetarian cuisine": "Vegetáriánus",
    "Subtotal": "Részösszeg",
    "Vezi oferta": "Ajánlatunk",
    "Momentan restaurantul nu acceptă comenzi online!": "Jelenleg az étterem nem fogad online rendelést!",
    "Plata cu cardul": "Kártyás fizetés",
    "Livrare": "Szállítás",
    "min.": "perc",
    "Distanță": "Távolság",
    "discount la prima comandă": "kedvezmény első rendelés alkalmával",
    "per pagină": "oldalanként",
    "Arată toate": "Összes",
    "Ordonează după": "Rendezés",
    "Recomandate": "Ajánlott",
    "Distanță": "Távolság",
    "Rating": "Értékelés",
    "Discount": "Kedvezmény",
    "Arată": "Megjelenítés",
    "Tip afișare": "Megjelenítési mód",
    "Restaurante în": "Vendéglők",
    "Filtre": "Szűrők",
    "Filtrează după": "Szűrés",
    "Livrare gratuită": "Ingyenes szállítás",
    "Plată online": "Online fizetés",
    "Plată la livrare": "Fizetés kiszállításkor",
    "stele": "csillag",
    "Tip mâncare": "Étel típus",
    "Mai multe": "Többet",
    "Mai putine": "Kevesebbet",
    "Adresa selectată": "Szállítási cím",
    "Suntem prezenți în": "Jelen vagyunk",
    "Restaurante partenere": "Partner vendéglők",
    "Total produse": "Termékek",
    "Coșul Dvs. este gol": "A kosarad üres",
    "Spre casă": "Tovább",
    "Coș": "Kosaram",
    "Actualizează": "Frissítés",
    "Șterge tot": "Összes törlése",
    "Înapoi la Restaurant": "Vissza a vendéglőhöz",
    "Finalizare comandă": "Rendelés véglegesítése",
    "Ești deja înregistrat? Te rugăm să te autentifici.": "Már van fiókod? Jelentkezz be.",
    "Click aici": "Kattints ide",
    "Adrese salvate": "Mentett címek",
    "Selectează adresa de livrare": "Válassz szállítási címet",
    "Doresc livrarea la o altă adresă": "Más címre szeretném a szállítást",
    "Adresa de livare": "Szállítási cím",
    "Prenume": "Keresztnév",
    "Nume": "Vezetéknév",
    "Strada": "Utca",
    "strada-placeholder": "Írd be az utca nevét",
    "strada-placeholder": "Utca",
    "google-address-label": "Lakcím: utca, város",
    "Localitate": "Település",
    "Cod poștal": "Irányítószám",
    "Județ": "Megye",
    "Adresa - Strada, Număr, Bloc, Scara, Apartament, etc.": "Cím - Utca, házszám, tömbház, lépcsőház, szám, stb.",
    "adrese-inregistrate-address-street": "Cím - Utcanév (Benedek Elek utca)",
    "adrese-inregistrate-address-street-number": "Cím - Házszám (14)",
    "adrese-inregistrate-address-details": "Cím - Tömbház, lépcsőház, szám, stb.",
    "Nr. telefon": "Telefonszám",
    "Adresa de email": "E-mail cím",
    "Salvează adresa între favorite": "Mentés a kedvencek közé",
    "Doresc factură pe firmă": "Szeretnék számlát cégre",
    "Denumire companie": "Cégnév",
    "Nr. Reg. Com.": "Cégjegyzékszám",
    "Cod fiscal": "Adószám",
    "Note comandă": "Megjegyzés a rendeléshez",
    "Abonare la newsletter": "Feliratkozom a hírlevélre",
    "Cash": "Készpénz",
    "Online cu Card": "Online bankkártyával",
    "Livrare": "Kiszállítás",
    "Ridicare Personală": "Személyes felvét",
    "Livrare Contactless": "Érintésmentes szállítás",
    "Sunt de acord cu termenii și condițiile": "Egyetértek a feltételekkel",
    "Întroduceți date valide": "Kérünk érvényes adatokat adj meg",
    "Nu": "Nem",
    "Da": "Igen",
    "Salvează cont nou": "Új fiók mentése",
    "Comandă în curs de trimitere": "Küldés folyamatban",
    "Costul ambalajului se calculează de către restaurant și va fi adăugat la bonul fiscal.": "A csomagolás díját a vendéglő állapítja meg és kasszablokkon fog megjelenni.",
    "Trimite": "Küldés",
    "Plătesc": "Fizetek",
    "Coș gol": "Üres kosár",
    "Coșul tău este gol. Pentru a adauga produse în coș te rugăm să accesezi pagina Restaurantelor.": "Kosarad üres. Ételrendeléshez kérünk keresd fel a vendéglők oldalát.",
    "Vrei să adaugi o adresă nouă?": "Új címet szeretnél hozzáadni?",
    "Click aici": "Kattints ide",
    "Nume adresă nouă": "Új cím neve",
    "Salvează": "Mentés",
    "Adresă": "Cím",
    "Modifică": "Módosítás",
    "Denumire adresă": "Cím neve",
    "Ești sigur că vrei să ștergi această adresă din listă?": "Biztos törlöd a címet?",
    "Închide!": "Bezár!",
    "Nr. comandă": "Rendelésazonosító",
    "Data": "Dátum",
    "Destinatar": "Címzett",
    "Valoare": "Érték",
    "Stare": "Állapot",
    "Detalii": "Részletek",
    "Imediat": "Azonnali",
    "În așteptare": "Kiküldés alatt",
    "Adresa de livrare": "Szállítási cím",
    "Adresa de facturare": "Számlázási cím",
    "Metoda de plată": "Fizetési mód",
    "Detalii Cont": "Ügyfélfiók",
    "Adresa": "Cím",
    "Jud. Covasna": "Kovászna megye",
    "Covasna": "Kovászna",
    "Brașov": "Brassó",
    "Harghita": "Hargita",
    "Probleme de autentificare. Te rugăm logează-te din nou": "Bejelentkezési hiba. Megkérünk próbálj újra bejelentkezni",
    "Confirmare parolă": "Jelszó emlékeztető",
    "lasă gol pentru a ramâne neschimbată": "hagyd üresen ha nem módosítod",
    "Parola": "Jelszó",
    "Parola nouă": "Új jelszó",
    "Parola actuală": "Aktuális jelszó",
    "Panou de control": "Ügyfélkapu",
    "Setări cont": "Beállítások",
    "Comenziile mele": "Rendeléseim",
    "Review-urile mele": "Értékeléseim",
    "Abonare la newsletter": "Feliratkozás hírlevélre",
    "O comandă poate conține numai un restaurant": "Egy rendelés egy vendéglőt tartalmazhat",
    "Resetare parolă": "Új jelszó kérése",
    "Ai uitat parola? Te rugăm să introduci adresa ta de email în câmpul de mai jos. În scurt timp vei primi un email": "Elfelejtetted a jelszavat? Kérünk, írd be az e-mail címed az alábbi mezőbe. Hamarosan kapsz egy e-mailt",
    "Codul de confirmare": "Megerősítő kód",
    "Parola nouă": "Új jelszó",
    "Confirmă Parola": "Erősítse meg a jelszót",
    "Resetează": "Alapértékek",
    "Parola nu se potrivește cu parola de confirmare": "A jelszó nem egyezik meg a megerősítő jelszóval",
    "Autentificare": "Bejelentkezés",
    "Autentificare cu email înregistrat": "Bejelentkezés regisztrált e-mail címmel",
    "câmpuri obligatorii": "kötelező mezők",
    "Ține-mă in minte": "Emlékezz rám",
    "Ai uitat parola": "Elfelejtetted a jelszavad",
    "Intră în cont": "Belépés",
    "Nu ai cont": "Nincs fiókod",
    "Utilizatorul nu este confirmat": "A felhasználót nem erősítették meg",
    "Adresa de email sau parola este greşită": "Az e-mail cím vagy a jelszó helytelen",
    "Creare cont nou": "Új fiók létrehozása",
    "Completează": "Töltsd ki a mezőket",
    "Creează": "Létrehozás",
    "Parola nu se potrivește cu parola de confirmare": "A jelszó nem egyezik meg a megerősítő jelszóval",
    "Adresa de e-mail pe care ați introdus-o este deja înregistrată": "A megadott e-mail cím már regisztrálva van",
    "Parola trebuie să aibă minim 6 caractere": "A jelszónak legalább 6 karakter hosszúnak kell lennie",
    "Te-ai inregistrat cu success": "Sikeresen regisztráltál",
    "Contul dvs. a fost creat. Un link de confirmare a fost trimis la adresa de e-mail.": "A felhasználói fiókod elkészült. Megerősítő linket küldtünk az e-mail címedre.",
    "Loghează-te": "Belépés",
    "Confirmă parola nouă": "Erősítse meg az új jelszót",
    "Contul meu": "Az én fiókom",
    "Panou de control": "Vezérlőpult",
    "Deconectare": "Kilépés",
    "Bun venit": "Üdvözölünk",
    "Comandă sub": "Rendelés kevesebb mint",
    "Comandă peste": "Rendelés legalább",
    "lei": "lej",
    "Livrare in": "Szállítás",
    "Comandă trimisă": "Megrendelés elküldve",
    "Comanda ta a fost înregistrată și plătită. Codul comenzii": "Rendelésedet regisztráltuk és a kifizetés megtörtént. Rendelésazonosító",
    "În scurt timp vei primi un email de confirmare. Îți mulțumim.": "Hamarosan megerősítő e-mailt fogsz kapni. Köszönjük.",
    "Comanda ta a fost înregistrată. Codul comenzii": "Rendelésed rögzítettük. Rendelésazonosító",
    "Nu aveți momentan comandă de trimis": "Jelenleg nincs küldhető megrendelés",
    "Termenile trebuie acceptate": "A feltételeket el kell fogadni",
    "Cum funcționează?": "Hogyan működik?",
    "Mâncarea ta este la un click distanță": "Kedvenc ételeid egy kattintásra vannak",
    "INTRODUCI ADRESA TA": "KIVÁLASZTOD A TELEPÜLÉSED",
    "ALEGI UN RESTAURANT": "KIVÁLASZTASZ EGY ÉTTERMET",
    "ALEGI CUM PLĂTEȘTI": "KIVÁLASZTOD HOGYAN FIZETSZ",
    "POFTĂ BUNĂ": "JÓ ÉTVÁGYAT",
    "Pentru a găsi ofertele tuturor restaurantelor din zona ta": "Hogy lásd az összes étterem ajánlatát",
    "Poți alege dintr-o gamă variată de preparate gustoase": "Sokféle ételkülönlegesség közül választhatsz",
    "Poți plăti cash la livrare sau online": "Fizethetsz bankkártyával online vagy készpénzzel a futárnál",
    "Savurează preparatele comandate": "Élvezd a megrendelt ételkülönlegességeket",
    "Orașe unde suntem prezenți": "Városok ahol jelen vagyunk",
    "Cele mai populare restaurante": "Népszerű éttermek",
    "Recomandat": "Ajánlott",
    "Vezi oferta": "Részletek",
    "Contact": "Kapcsolat",
    "Termeni și condiții de utilizare": "Felhasználási feltételek",
    "Politici de confidențialitate": "Személyes adatok védelme",
    "Ștergere cont": "Fióktörlési kérelem",
    "Înregistrare restaurant": "Vendéglő regisztráció",
    "Departamentul Relații Clienți": "Ügyfélszolgálat",
    "Luni - Vineri": "Hétfő-Péntek",
    "Administrator Platforma Ospatarul.ro": "Ospatarul.ro üzemeltetője",
    "str. Kossuth Lajos nr. 1": "Kossuth Lajos utca 1",
    "520008 - Sf. Gheorghe, Jud. Covasna": "520008 - Sepsiszentgyörgy, Kovászna megye",
    "Suport Clienți": "Ügyfélszolgálat",
    "Numele complet": "Név",
    "Adresa de e-mail": "E-mail cím",
    "Telefon": "Telefonszám",
    "Nr. comandă": "Rendelésazonosító",
    "Mesaj": "Üzenet",
    "Trimite": "Elküldöm",
    "Esti proprietar de restaurant": "Vendéglő tulajdonos vagy",
    "Ești proprietar de restaurant": "Vendéglő tulajdonos vagy",
    "Solicitare ștergere cont utilizator": "Fióktörlési kérelem",
    "Dorești să înscrii un restaurant?": "Szeretnél éttermeddel csatlakozni?",
    "Completează formularul de înscriere și te vom contacta în curând pentru a oferi detalii în legătura listarea restaurantului. Listarea propriu zisă este gratuită și este necesar doar încheierea unui contract și validarea informațiilor furnizate.": "Töltsd ki az űrlapot és rövidesen kapcsolatba lépünk veled. Az étterem megjelenése az oldalon teljesen ingyenes és csak egy szerződés megkötése és az adatok ellenőrzése szükséges az induláshoz.",
    "Motivul ștergerii contului": "A fióktörlés oka",
    "request-account-removal-message-1": "Ezen az oldalon keresztül könnyedén kérheti a Ospătarul mobilalkalmazásban létrehozott felhasználói fiókjának végleges törlését rendszerünkből. Felhívjuk figyelmét, hogy a fiók törlése végleges, és minden, az alkalmazásunkban tárolt adat is törlésre kerül.",
    "request-account-removal-message-2": "Kérjük, töltse ki az alábbi űrlapot a törlési kérelem benyújtásához. Amint beérkezik a kérelme, feldolgozzuk azt, és értesítést küldünk a megadott e-mail címre a törlés sikerességéről.",
    "Solicitarea de ștergere a contului de utilizator a fost transmisă cu succes!": "A fióktörlési kérelem sikeresen elküldve!",
    "Solicitarea de ștergere a contului nu a fost trimisă. Te rugăm să ne contactezi prin e-mail.": "A fióktörlési kérelem elküldése sikertelen volt. Kérjük, lépjen kapcsolatba velünk e-mailben.",
    "Localitate": "Település",
    "Selectează localitatea": "Válaszd ki a települést",
    "Numele restaurantului": "Vendéglő neve",
    "Ciba": "Csiba",
    "Ciceu": "Csíkcsicsó",
    "Șoimeni": "Csíkcsomortán",
    "Delnița": "Csíkdelne",
    "Misentea": "Csíkmindszent",
    "Păuleni-Ciuc": "Csíkpálfalva",
    "Sântimbru": "Csíkszentimre",
    "Sâncrăieni": "Csíkszentkirály",
    "Leliceni": "Csíkszentlélek",
    "Sânsimion": "Csíkszentsimon",
    "Miercurea-Ciuc": "Csíkszereda",
    "Miercurea-Ciuc (Șumuleu Ciuc)": "Csíkszereda (Csíksomlyó)",
    "Miercurea-Ciuc (Cioboteni)": "Csíkszereda (Csobotfalva)",
    "Miercurea-Ciuc (Fitod)": "Csíkszereda (Fitód)",
    "Miercurea-Ciuc (Cartier Toplița)": "Csíkszereda (Taploca)",
    "Miercurea-Ciuc (Jigodin-Băi)": "Csíkszereda (Zsögöd)",
    "Miercurea Ciuc": "Csíkszereda",
    "Angheluș": "Angyalos",
    "Arcuș": "Árkos",
    "Bicfalău": "Bikfalva",
    "Bodoc": "Bodok",
    "Vâlcele": "Előpatak",
    "Etfalău": "Étfalva",
    "Lunca Calnicului": "Farkasvágó",
    "Ghidfalău": "Gidófalva",
    "Holzindustrie Schweighofer (Reci)": "Holzindustrie Schweighofer (Réty)",
    "Ilieni": "Illyefalva",
    "Chilieni": "Kilyén",
    "Chichiș": "Kökös",
    "Valea Crișului": "Kőröspatak",
    "Lisnău": "Lisznyó",
    "Reci": "Réty",
    "Sfântu Gheorghe (Câmpul Benedek)": "Sepsiszentgyörgy (Benedek Mező)",
    "Sfântu Gheorghe (Salomer)": "Sepsiszentgyörgy (Szalomér)",
    "Sfântu Gheorghe (Câmpul Frumos)": "Sepsiszentgyörgy (Szépmező)",
    "Sâncraiu": "Sepsiszentkirály",
    "Șugaș Băi": "Sugásfürdő",
    "Sântionlunca": "Szentivánlaborfalva",
    "Coșeni": "Szotyor",
    "Ozun": "Uzon",
    "Zălan": "Zalán",
    "Zoltan": "Zoltán",
    "Belani": "Bélafalva",
    "Brețcu": "Bereck",
    "Cernat": "Csernáton",
    "Lutoasa": "Csomortán",
    "Estelnic": "Esztelnek",
    "Alungeni": "Futásfalva",
    "Ghelința": "Gelence",
    "Haraly": "Haraly",
    "Hătuica": "Hatolyka",
    "Hilib": "Hilib",
    "Icafalău": "Ikafalva",
    "Imeni": "Imecsfalva",
    "Mereni": "Kézdialmás",
    "Petriceni": "Kézdikővár",
    "Mărcușa": "Kézdimárkosfalva",
    "Mărtineni": "Kézdimartonfalva",
    "Mărtănuș": "Kézdimartonos",
    "Tinoasa": "Kézdisárfalva",
    "Valea Seacă": "Kézdiszárazpatak",
    "Poian": "Kézdiszentkereszt",
    "Sânzieni": "Kézdiszentlélek",
    "Cașinu Mic": "Kiskászon",
    "Valea Scurtă": "Kurtapatak",
    "Lemnia": "Lemhény",
    "Lunga": "Nyújtód",
    "Ojdula": "Ozsdola",
    "Săsăuși": "Szászfalu",
    "Peteni": "Székelypetőfalva",
    "Cătălina": "Szentkatolna",
    "Turia": "Torja",
    "Zăbala": "Zabola",
    "Târgu Secuiesc": "Kézdivásárhely",
    "Targu Secuiesc": "Kézdivásárhely",
    "Brateș": "Barátos",
    "Țufalău": "Cófalva",
    "Chiuruș": "Csomakőrös",
    "Leț": "Lécfalva",
    "Boroșneu Mare": "Nagyborosnyó",
    "Telechia": "Orbaitelek",
    "Pachia": "Páké",
    "Păpăuți": "Papolc",
    "Tamașfalău": "Székelytamásfalva",
    "Surcea": "Szörcse",
    "Zăbala": "Zabola",
    "Zagon": "Zágon",
    "Covasna": "Kovászna",
    "Vezi Restaurante": "Vendéglők",
    "Restaurant Castel": "Kastély Vendéglő",
    "Nr.ORC": "Cégbejegyzési szám",
    "CUI": "Adószám",
    "Creasmart SRL": "Creasmart KFT",
    "Mesajul Tău a fost trimis Departamentului de Relații Clienți. Te vom contacta curând.": "Üzeneted továbbítottuk az ügyfélközpontnak. Rövidesen kapcsolatba lépünk Veled.",
    "Mesajul Tău nu a fost trimis. Te rugăm să ne cauți prin e-mail.": "Üzeneted elküldése sikertelen volt. Megkérünk írj e-mailt nekünk.",
    "Nume complet": "Név",
    "Momentan restaurantul este închis": "Jelenleg a vendéglő zárva van",
    "Politici de confidențialitate": "Személyes adatok védelme",
    "Termeni și condiții": "Felhasználási feltételek",
    "în Ciba": "Csibában",
    "în Ciceu": "Csíkcsicsóban",
    "în Șoimeni": "Csíkcsomortánban",
    "în Delnița": "Csíkdelnében",
    "în Misentea": "Csíkmindszentben",
    "în Păuleni-Ciuc": "Csíkpálfalván",
    "în Sântimbru": "Csíkszentimrén",
    "în Sâncrăieni": "Csíkszentkirályon",
    "în Leliceni": "Csíkszentléleken",
    "în Sânsimion": "Csíkszentsimon",
    "în Miercurea-Ciuc": "Csíkszeredában",
    "în Miercurea-Ciuc (Șumuleu Ciuc)": "Csíksomlyón",
    "în Miercurea-Ciuc (Cioboteni": "Csobotfalván",
    "în Miercurea-Ciuc (Fitod)": "Fitódon",
    "în Miercurea-Ciuc (Cartier Toplița)": "Taplocán",
    "în Miercurea-Ciuc (Jigodin-Băi)": "Zsögödön",
    "în Angheluș": "Angyaloson",
    "în Arcuș": "Árkoson",
    "în Bicfalău": "Bikfalván",
    "în Bodoc": "Bodokon",
    "în Vâlcele": "Előpatakon",
    "în Etfalău": "Étfalván",
    "în Lunca Calnicului": "Farkasvágón",
    "în Ghidfalău": "Gidófalván",
    "în Bicfalău": "Bikfalván",
    "în Angheluș": "Angyaloson",
    "în Arcuș": "Árkoson",
    "în Holzindustrie Schweighofer (Reci)": "Holzindustrie Schweighofernél",
    "în Ilieni": "Illyefalván",
    "în Chilieni": "Kilyénben",
    "în Chichiș": "Kökösön",
    "în Valea Crișului": "Kőröspatakon",
    "în Lisnău": "Lisznyóban",
    "în Reci": "Rétyen",
    "în Sfântu Gheorghe (Salomer)": "Sepsiszentgyörgy (Szalomér)",
    "în Sfântu Gheorghe (Câmpul Benedek)": "Benedek Mezőn",
    "în Sfântu Gheorghe (Câmpul Frumos)": "Szépmezőn",
    "în Sâncraiu": "Sepsiszentkirályon",
    "în Șugaș Băi": "Sugásfürdőn",
    "în Sântionlunca": "Szentivánlaborfalván",
    "în Coșeni": "Szotyorban",
    "în Ozun": "Uzonban",
    "în Zălan": "Zalánban",
    "în Zoltan": "Zoltánban",
    "în Calnic": "Kálnokon",
    "în Belani": "Bélafalván",
    "în Brețcu": "Bereckben",
    "în Cernat": "Csernátonban",
    "în Lutoasa": "Csomortánban",
    "în Estelnic": "Esztelneken",
    "în Alungeni": "Futásfalván",
    "în Ghelința": "Gelencén",
    "în Haraly": "Haralyban",
    "în Hătuica": "Hatolykán",
    "în Hilib": "Hiliben",
    "în Icafalău": "Ikafalván",
    "în Imeni": "Imecsfalván",
    "în Mereni": "Kézdialmáson",
    "în Petriceni": "Kézdikővár",
    "în Mărcușa": "Kézdimárkosfalván",
    "în Mărtineni": "Kézdimartonfalván",
    "în Mărtănuș": "Kézdimartonoson",
    "în Tinoasa": "Kézdisárfalván",
    "în Valea Seacă": "Kézdiszárazpatakon",
    "în Poian": "Kézdiszentkereszten",
    "în Sânzieni": "Kézdiszentléleken",
    "în Cașinu Mic": "Kiskászonba",
    "în Valea Scurtă": "Kurtapatakon",
    "în Lemnia": "Lemhényben",
    "în Lunga": "Nyújtódon",
    "în Ojdula": "Ozsdolán",
    "în Săsăuși": "Szászfalun",
    "în Peteni": "Székelypetőfalván",
    "în Cătălina": "Szentkatolnán",
    "în Turia": "Torján",
    "în Zăbala": "Zabolán",
    "în Târgu Secuiesc": "Kézdivásárhelyen",
    "în Brateș": "Barátoson",
    "în Țufalău": "Cófalván",
    "în Chiuruș": "Csomakőrösön",
    "în Leț": "Lécfalván",
    "în Boroșneu Mare": "Nagyborosnyón",
    "în Telechia": "Orbaiteleken",
    "în Pachia": "Pákén",
    "în Păpăuți": "Papolcon",
    "în Tamașfalău": "Székelytamásfalván",
    "în Surcea": "Szörcsén",
    "în Zăbala": "Zabolán",
    "în Zagon": "Zágonban",
    "în Covasna": "Kovásznán",
    "Calnik": "Kálnok",
    "order_send_in_progress": "A rendelés küldése folyamatban van. Ha gyenge az internetkapcsolatod és egy percen belül nem sikerül elküldeni, frissítsd az oldalt és próbáld újra.",
    "Nu mai puteți adăuga alte elemente": "Több elemet nem lehet hozzáadni",
    "Calnic": "Kálnok",
    "Comanda a fost deja plătită și trimisă.": "Rendelésed már ki van fizetve és rögzítve.",
    "Comandă procesată": "Rendelés feldolgozva",
    "Modalitate de plată": "Fizetési mód",
    "Modalitate de livrare": "Szállítási mód",
    "La curier prin card": "A futárnál bankkártyával",
    "xxx": "yyy",
    "Datele au fost salvate": "Az adatokat elmentettük",
    "Descarcă acum aplicația Ospătarul": "Töltsd le az Ospătarul applikációt",
    "Adresă invalidă. Vă rugăm întroduceți date corecte!": "Helytelen cím. Megkérünk adj meg helyes adatokat!",
    "Confirmată": "Visszaigazolva",
    "adrese-inregistrate-address": "Cím - Utca, házszám, Tömbház, Lépcsőház, Apartman, stb.",
    "google-address-placeholder": "Írd be a címed",
    "google-address-number": "Házszám",
    "google-address-block": "Tömbház",
    "google-address-staircase": "Lépcsőház",
    "google-address-floor": "Emelet",
    "google-address-apartment": "Apartman",
    "address-name": "A cím megnevezése (munkahely, otthon) *",
    "address-deleted": "A címet sikeresen törölve",
    "restaurant-doesnt-deliver-to-this-address": "{0} nem szállít {1} területére",
    "select-nr-or-items": "válassz {0} darabot",
    "select-nr-or-items-alert": "Válassz {0} darabot a {1} kategóriából",
    "select-min-nr-or-items-alert": "Válassz minimum {0} darabot a {1} kategóriából",
    "select-max-nr-or-items-alert": "Válassz maximum {0} darabot a {1} kategóriából",
    "momentan-restaurant-inchis": "Jelenleg a vendéglő zárva van",
    "adrese-inregistrate": "Regisztrált címek",
    "payment-failed": "Fizetés sikertelen",
    "payment-failed-description": "A bankkártyás fizetés nem sikerült.",
    "payment-restart": "Újra próbálom",
    "addressNote": "Megjegyzés a címhez",
    "withoutCutlery": "Nem kérek evőeszközt",
    "Transferă-te la Hamm acum!": "Költözz át már most a Hammra!"
};


import "@fontsource/montserrat-alternates";
import "./src/kajakiraly-app/locale/locale.js";
import './src/kajakiraly-app/assets/smart-app-banner.js';
import './src/kajakiraly-app/assets/smart-app-banner.css';
import '@webcomponents/webcomponentsjs/webcomponents-loader.js';
import './src/shared-styles.css';
import './favicon.png';

import('/src/kajakiraly-app/kajakiraly-app.js').then((module) => { }).catch((reason) => { console.log("Module failed to load", reason); });
import('/src/kajakiraly-app/shared-styles.js').then((module) => { }).catch((reason) => { console.log("Module failed to load", reason); });

var version = "20210614";
var lang = "";
window.code_version = version;
window.storage_version = version;

window.dataLayer = [];
if (typeof (Storage) !== "undefined") {
	lang = localStorage.getItem("lang");
	if (!lang) {
		localStorage.setItem("lang", 'ro');
		lang = "ro";
	}
	let sver = localStorage.getItem("storage_version");
	if (sver && sver !== "undefined") {
		if (sver != window.storage_version) {
			localStorage.removeItem("userOrder");
			localStorage.removeItem("filters");
			localStorage.removeItem("locations");
			localStorage.removeItem("selected-location");
			localStorage.removeItem("userData");
			localStorage.setItem("storage_version", window.storage_version);
		}
	} else {
		localStorage.removeItem("userOrder");
		localStorage.removeItem("filters");
		localStorage.removeItem("locations");
		localStorage.removeItem("selected-location");
		localStorage.removeItem("userData");
		localStorage.setItem("storage_version", window.storage_version);
	}
	localStorage.setItem("version", window.code_version);
}

if ('serviceWorker' in navigator) {
	window.addEventListener('load', () => {
		navigator.serviceWorker.getRegistrations().then(function (registrations) {
			for (let registration of registrations) {
				registration.unregister();
				console.log('ServiceWorker is off!');
			}
		});
	});
}

window.isMobile = false;
if (/Mobi|Android/i.test(navigator.userAgent)) {
	window.isMobile = true;
	// mobile!
	if (lang == "ro") {
		new SmartBanner({
			daysHidden: 0,   // days to hide banner after close button is clicked (defaults to 15)
			daysReminder: 7, // days to hide banner after "VIEW" button is clicked (defaults to 90)
			appStoreLanguage: 'ro', // language code for the App Store (defaults to us)
			title: 'Ospătarul',
			author: 'Creasmart',
			button: 'VEZI',
			store: {
				ios: 'On the App Store',
				android: 'In Google Play'
			},
			price: {
				ios: 'Gratuit',
				android: 'Gratuit'
			}
			// , force: 'ios' // Uncomment for platform emulation
		});
	}
	else if (lang = "hu") {
		new SmartBanner({
			daysHidden: 0,   // days to hide banner after close button is clicked (defaults to 15)
			daysReminder: 7, // days to hide banner after "VIEW" button is clicked (defaults to 90)
			appStoreLanguage: 'hu', // language code for the App Store (defaults to us)
			title: 'Ospătarul',
			author: 'Creasmart',
			button: 'Megnézem',
			store: {
				ios: 'On the App Store',
				android: 'In Google Play'
			},
			price: {
				ios: 'Ingyenes',
				android: 'Ingyenes'
			}
			// , force: 'ios' // Uncomment for platform emulation
		});
	}
	else {
		new SmartBanner({
			daysHidden: 0,   // days to hide banner after close button is clicked (defaults to 15)
			daysReminder: 7, // days to hide banner after "VIEW" button is clicked (defaults to 90)
			appStoreLanguage: 'us', // language code for the App Store (defaults to us)
			title: 'Kajakiraly',
			author: 'Creasmart Srl',
			button: 'VIEW',
			store: {
				ios: 'On the App Store',
				android: 'In Google Play',
			},
			price: {
				ios: 'FREE',
				android: 'FREE'
			}
			// , force: 'ios' // Uncomment for platform emulation
		});
	}
}
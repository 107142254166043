// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*, ::after, ::before {
    box-sizing: border-box;
  }

  body {
    margin: 35px 0 0 0;
    color: #2d333c;
  }

  .footer {
    height: 90px;
    padding: 20px;
    background-color: #343b46;
    font-size: 12px;
    color: #fff;
  }

kajakiraly-app {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

@media (min-width: 1680px) {
  kajakiraly-app {
    max-width: 1630px;
  }
}`, "",{"version":3,"sources":["webpack://./src/shared-styles.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;IAClB,cAAc;EAChB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,eAAe;IACf,WAAW;EACb;;AAEF;EACE,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":["*, ::after, ::before {\n    box-sizing: border-box;\n  }\n\n  body {\n    margin: 35px 0 0 0;\n    color: #2d333c;\n  }\n\n  .footer {\n    height: 90px;\n    padding: 20px;\n    background-color: #343b46;\n    font-size: 12px;\n    color: #fff;\n  }\n\nkajakiraly-app {\n  margin-right: auto;\n  margin-left: auto;\n  padding-right: 15px;\n  padding-left: 15px;\n  width: 100%;\n}\n\n@media (min-width: 1680px) {\n  kajakiraly-app {\n    max-width: 1630px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
